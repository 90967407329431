import { createContext, useContext, useEffect, useState } from "react";
import { useWindowWidth } from "../Utils/CustomHooks/UseWindowWidth";
import { useCalendarWidthContext } from "./CalendarWidthContextProvider";

const DateCountContext = createContext(null);

export function DateCountContextProvider({ children }) {
  const [dateCount, setDateCount] = useState(null);
  const { calendarWidth } = useCalendarWidthContext();

  const getColumnCount = (width) => {
    if (width < 500) return 1;
    if (width < 1000) return 3;
    return 7;
  };

  useEffect(() => {
    if (calendarWidth == null || calendarWidth === 0) {
      return;
    }

    const translatedWidth = calendarWidth - 38; // time width
    const columnCount = getColumnCount(translatedWidth);
    setDateCount(columnCount);
  }, [calendarWidth]);

  return (
    <DateCountContext.Provider value={{ dateCount, setDateCount }}>
      {children}
    </DateCountContext.Provider>
  );
}

export function useDateCountContext() {
  const context = useContext(DateCountContext);
  if (!context) {
    throw new Error(
      "useDateCountContext must be used within a DateCountContextProvider"
    );
  }
  return context;
}
