import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import "chartjs-adapter-date-fns"; // For date formatting on the x-axis
import { Modal } from "../../Shared/Components/Modal";
import { useActivityStats } from "../Utils/CustomHooks/useActivityStats";
import { useCategoryStats } from "../Utils/CustomHooks/useCategoryStats";

ChartJS.register(
  TimeScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export function CategoryStatsModal({ selectedCategory }) {
  const { timeUnit, dataList } = useCategoryStats(selectedCategory);
  if (selectedCategory == null || dataList == null || timeUnit == null)
    return null;
  console.log("test");

  const data = {
    labels: dataList.map((entry) => entry.date), // Dates for x-axis
    datasets: [
      {
        label: "Total Time",
        data: dataList.map((entry) => entry.totalTime), // Time data for y-axis
        fill: false,
        borderColor: selectedCategory.color, // Dynamic line color
        tension: 0.1, // Smooth line curve
      },
    ],
  };

  const options = {
    scales: {
      x: {
        type: "time", // Specifies time-based x-axis
        time: timeUnit,
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Total Time",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
    },
    responsive: true,
  };

  return (
    <Modal title="Activity Statistics" hasSuccess={false}>
      <div className="w-full mx-auto">
        <h3 className="font-semibold text-black dark:text-white">
          {selectedCategory.title}
        </h3>
        <div className="w-96 p-4">
          <Line data={data} options={options} />
        </div>
      </div>
    </Modal>
  );
}
