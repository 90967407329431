import { useCurrentTime } from "../Utils/CustomHooks/useCurrentTime";
import { useEffect, useState } from "react";

export function TimeLine() {
  const time = useCurrentTime();

  const [topHeight, setTopHeight] = useState("");
  useEffect(() => {
    if (time == null) return;
    const fullHour = time.getHours() + time.getMinutes() / 60; //ex 7.85
    const segmentMultiplier = 4;
    setTopHeight(
      "calc(0.5rem + 0.75rem * " + fullHour * segmentMultiplier + ")"
    ); //0.5rem is the py-2 padding above the columns
  }, [time]);

  return (
    <div
      className="absolute w-full z-10 pointer-events-none select-none"
      style={{ top: topHeight }}
    >
      <div className="bg-primary h-[2px]"></div>
    </div>
  );
}
