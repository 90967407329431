import { useEffect, useState } from "react";
import { getStatistics } from "../../../Endpoints/Statistics/StatisticsEndpoints";
import { useDateContext } from "../../Shared/Contexts/DateContextProvider";
import { StatisticsHeader } from "./StatisticsHeader";
import { useSelectedDates } from "../Utils/CustomHooks/useSelectedDates";
import { useStatisticsContext } from "../../Shared/Contexts/StatisticsContextProvider";
import { ActivitiesStats } from "./ActivitiesStats";
import { CategoriesStats } from "./CategoriesStats";
import { BlockedTimeStats } from "./BlockedTimeStats";
import useTheme from "../../Shared/Utils/useTheme";
import { OtherStats } from "./OtherStats";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { ActivityStatsModal } from "./ActivityStatsModal";
import { CategoryStatsModal } from "./CategoryStatsModal";
import { TargetHeatMap } from "./TargetHeatMap";

export function Statistics() {
  const { startDate, endDate } = useSelectedDates();
  const { setStats } = useStatisticsContext();
  const [activityStatsModalOpen, setActivityStatsModalOpen] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const [categoryStatsModalOpen, setCategoryStatsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  useEffect(() => {
    if (startDate == null || endDate == null) return;
    getStatistics(startDate, endDate).then((res) => {
      setStats(res.data);
    });
  }, [startDate, endDate]);

  const handleActivitySelected = (activity) => {
    setSelectedActivity(activity);
    setActivityStatsModalOpen(true);
  };
  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setCategoryStatsModalOpen(true);
  };

  return (
    <div className="container">
      <StatisticsHeader />
      <div className="px-2 md:px-12 flex flex-col gap-3">
        <div className="flex gap-3">
          <div className="max-w-[38rem] w-full h-80 p-4 rounded bg-secondaryLight dark:bg-dark ">
            <ActivitiesStats onSelectActivity={handleActivitySelected} />
          </div>
          <div className="text-black max-w-[20rem] w-full h-80 p-4 rounded bg-secondaryLight dark:bg-dark dark:text-white">
            <OtherStats />
          </div>
        </div>

        <div className="flex gap-3">
          <div className="max-w-[48rem] w-min h-80 p-4 rounded bg-secondaryLight dark:bg-dark ">
            <CategoriesStats onSelectCategory={handleCategorySelected} />
          </div>
          <div className="max-w-[38rem] w-full h-80 p-4 bg-secondaryLight dark:bg-dark">
            <BlockedTimeStats />
          </div>
        </div>
        <div className="max-w-[58.75rem] w-full h-min p-4 bg-secondaryLight dark:bg-dark">
          <TargetHeatMap />
        </div>
      </div>
      <ModalContext.Provider
        value={[activityStatsModalOpen, setActivityStatsModalOpen]}
      >
        <ActivityStatsModal selectedActivity={selectedActivity} />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[categoryStatsModalOpen, setCategoryStatsModalOpen]}
      >
        <CategoryStatsModal selectedCategory={selectedCategory} />
      </ModalContext.Provider>
    </div>
  );
}
