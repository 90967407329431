import { useStatisticsContext } from "../../Shared/Contexts/StatisticsContextProvider";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export function ActivitiesStats({ onSelectActivity, limit = null }) {
  const { stats } = useStatisticsContext();
  if (stats == null) return <></>;
  const data = {
    labels:
      limit !== null
        ? stats.activities.map((a) => a.title).slice(0, limit)
        : stats.activities.map((a) => a.title),
    datasets: [
      {
        label: "Time",
        data:
          limit !== null
            ? stats.activities.map((a) => a.totalTime).slice(0, limit)
            : stats.activities.map((a) => a.totalTime),
        backgroundColor:
          limit !== null
            ? stats.activities.map((a) => a.color).slice(0, limit)
            : stats.activities.map((a) => a.color),
        borderColor: "#ffffff",
        borderWidth: 1,
        barThickness: 20, // Adjust the bar thickness
      },
    ],
  };

  const options = {
    responsive: true,
    indexAxis: "y", // Make bars horizontal
    onClick: (event, elements) => {
      if (elements[0] == null) return;
      const index = elements[0].index;
      const activity = stats.activities[index];
      onSelectActivity(activity);
    },

    plugins: {
      legend: {
        // position: "top",
        display: false, // Fully hides the legend and the colored square
      },
      title: {
        display: true,
        text: "Time Per Activity" + (limit !== null ? ` (top ${limit})` : ""),
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    // <div style={{ width: "100%", maxWidth: "800px" }}>
    <Bar data={data} options={options} />
    // </div>
  );
}
