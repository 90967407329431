import { useEffect, useState } from "react";
import { useStatisticsContext } from "../../../Shared/Contexts/StatisticsContextProvider";
import { useSelectedDates } from "./useSelectedDates";
import {
  normalizeDate,
  toLocalISOString,
} from "../../../Shared/Utils/Functions/dateToNumber";

export function useTargetStats() {
  const { stats } = useStatisticsContext();
  const { startDate, endDate } = useSelectedDates();
  const [datePercentageMap, setDatePercentageMap] = useState(new Map());

  useEffect(() => {
    if (stats == null) return;

    const targets = getTargets(stats.activities, startDate, endDate);
    const newDatePercentageMap = new Map();

    // Loop through each date between start and end date
    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const normalizedDate = normalizeDate(currentDate);
      const blocks = stats.blocks.map((b) => ({
        ...b,
        date: normalizeDate(new Date(b.date)),
      }));

      const dateTargets = targets.filter((t) =>
        filterTargetByDate(t, normalizedDate)
      );

      const percentage = calculateTargetCompletionPercentage(
        dateTargets,
        blocks,
        stats.activities,
        normalizedDate
      );

      newDatePercentageMap.set(
        toLocalISOString(normalizedDate).split("T")[0],
        percentage
      );

      // Move to the next day
      currentDate.setDate(normalizedDate.getDate() + 1);
    }

    // Add all missing dates for the current year with percentage -1
    const yearStart = new Date(new Date().getFullYear(), 0, 1);
    const yearEnd = new Date(new Date().getFullYear(), 11, 31);
    currentDate = new Date(yearStart);

    while (currentDate <= yearEnd) {
      const normalizedDate = normalizeDate(currentDate);
      if (
        !newDatePercentageMap.has(
          toLocalISOString(normalizedDate).split("T")[0]
        )
      ) {
        newDatePercentageMap.set(
          toLocalISOString(normalizedDate).split("T")[0],
          -1
        );
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    // Sort map by date to ensure date order
    const sortedDatePercentageMap = new Map(
      Array.from(newDatePercentageMap.entries()).sort(
        ([a], [b]) => new Date(a) - new Date(b)
      )
    );

    setDatePercentageMap(sortedDatePercentageMap);
  }, [stats, startDate, endDate]);

  return { datePercentageMap };
}

function getTargets(activities, inputStartDate, inputEndDate) {
  // Iterate over activities and collect matching targets
  const matchingTargets = activities.flatMap((activity) =>
    activity.targets
      .filter((target) => {
        const targetStartDate = normalizeDate(new Date(target.startDate));
        const targetEndDate = target.endDate
          ? normalizeDate(new Date(target.endDate))
          : null;

        // Check if the target date range overlaps with the input date range
        return (
          (targetEndDate === null && targetStartDate <= inputEndDate) || // Includes targets with no end date that start before or within the input end date
          (targetEndDate !== null &&
            targetStartDate <= inputEndDate &&
            targetEndDate >= inputStartDate)
        );
      })
      .map((target) => ({
        ...target,
        activityIsArchived: activity.isDone,
        activityId: activity._id, // Add the activityId to the target
      }))
  );

  return matchingTargets;
}

function filterTargetByDate(target, date) {
  // Check if date is within the target date range

  if (target.startDate && target.endDate) {
    if (
      date < normalizeDate(new Date(target.startDate)) ||
      date > normalizeDate(new Date(target.endDate))
    ) {
      return false;
    }
  } else if (target.startDate) {
    if (date < normalizeDate(new Date(target.startDate))) {
      return false;
    }
  }

  const dayOfWeek = date
    .toLocaleString("en-us", { weekday: "short" })
    .toLowerCase(); // 'mon', 'tue', etc.
  const isWeekday = date.getDay() > 0 && date.getDay() < 6; // Mon-Fri (1-5)

  if (target.range === "daily") {
    return true;
  }

  if (target.range === "custom" && target.customRange[dayOfWeek]) {
    return true;
  }

  if (target.range === "weekDays" && isWeekday) {
    return true;
  }

  return false;
}

function calculateTargetCompletionPercentage(
  targets,
  blocks,
  activities,
  date
) {
  let completedTargets = 0;

  targets.forEach((target) => {
    let totalTime = 0;

    // Find all activityIds that could match this target, including linked activities
    const relatedActivityIds = activities
      .filter((activity) => activity.categoryId === target.activityId)
      .map((activity) => activity._id);

    // Include the target's own activityId
    relatedActivityIds.push(target.activityId);

    const matchingBlocks = blocks.filter(
      (block) =>
        relatedActivityIds.includes(block.activityId) &&
        block.date.getTime() === date.getTime()
    );

    // Calculate the total time in minutes for these matching blocks
    matchingBlocks.forEach((block) => {
      const blockDurationMinutes = (block.end - block.start + 1) * 15;
      totalTime += blockDurationMinutes;
    });

    target.totalTime = totalTime;
    target.totalBlocks = matchingBlocks.length;

    // Check if this target is done based on the minMax and type conditions
    let isDone = false;
    if (target.minMax === "min") {
      if (
        (target.type === "time" && target.totalTime >= target.time) ||
        (target.type === "block" && target.totalBlocks >= target.blocks)
      ) {
        isDone = true;
      }
    } else if (target.minMax === "max") {
      if (
        (target.type === "time" && target.totalTime <= target.time) ||
        (target.type === "block" && target.totalBlocks <= target.blocks)
      ) {
        isDone = true;
      }
    } else {
      if (
        (target.type === "time" && target.totalTime >= target.time) ||
        (target.type === "block" && target.totalBlocks >= target.blocks)
      ) {
        isDone = true;
      }
    }

    // Increment completedTargets if this target is done
    if (isDone) {
      completedTargets++;
    }
  });

  // Calculate the overall completion percentage
  if (completedTargets === 0 && targets.length === 0) return -2;
  const completionPercentage = (completedTargets / targets.length) * 100;
  return completionPercentage;
}
