import { useState } from "react";
import { Modal } from "../../Shared/Components/Modal";
import { BlockPreview } from "./BlockPreview";

export default function AddCategoryModal({ onCreateCategory }) {
  const handleCreateCategory = () => {
    onCreateCategory({
      title,
      color,
      type: "Categories",
      hasCustomColor: true,
    });
    clearValues();
  };

  const clearValues = () => {
    setTitle("");
    setColor("#4A88A4");
  };

  const [title, setTitle] = useState("");
  const [color, setColor] = useState("#4A88A4");

  const handleTitleChange = (value) => {
    setTitle(value);
  };

  const handleColorChange = (event) => {
    setColor(event.target.value);
  };
  const handleCancel = () => {
    clearValues();
  };

  return (
    <Modal
      title={"New Category"}
      successText={"Add Category"}
      onSuccess={handleCreateCategory}
      onCancel={handleCancel}
    >
      <div className="flex gap-5">
        <div>
          <BlockPreview title={title} color={color} />
        </div>

        <form className="text-black dark:text-white space-y-3">
          <TextInput
            title={"Title"}
            value={title}
            placeholder={"Category_1"}
            onValueChange={handleTitleChange}
          />

          {/* <TextInput
          title={"Color"}
          placeholder={color}
          onValueChange={handleColorChange}
        /> */}

          <input
            type="color"
            value={color}
            onChange={handleColorChange}
            className="border-0 rounded bg-none"
          />
        </form>
      </div>
    </Modal>
  );
}

export function TextInput({ title, placeholder, onValueChange }) {
  const tc = (input) => {
    onValueChange(input.target.value);
  };
  return (
    <div>
      <label
        htmlFor="price"
        className="block text-sm font-medium leading-6 text-black dark:text-white"
      >
        {title}
      </label>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          type="text"
          name={title}
          id="text"
          className="block bg-secondaryLight dark:bg-lightDark w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-600 dark:text-gray-100 ring-1 ring-inset ring-black dark:ring-gray-300 placeholder:text- dark:placeholder:text-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6"
          placeholder={placeholder}
          onChange={tc}
        />
      </div>
    </div>
  );
}
