import { useEffect, useState } from "react";
import { useDateCountContext } from "../../../Shared/Contexts/DateCountContextProvider";
import { useDateContext } from "../../../Shared/Contexts/DateContextProvider";
import { dateToNumber } from "../../../Shared/Utils/Functions/dateToNumber";

export function useSelectedDates() {
  const { dateCount } = useDateCountContext();
  const [dateSetting, setDateSetting] = useState(null);
  const [dates, setDates] = useState([]);
  const { date } = useDateContext();

  useEffect(() => {
    if (dateCount == null) return;

    if (dateCount === 7) setDateSetting("week");
    if (dateCount === 3) setDateSetting("3d");
    if (dateCount === 1) setDateSetting("day");
  }, [dateCount]);

  useEffect(() => {
    if (dateSetting == null) return;
    const weekDates = getDatesBySetting(date, "week");
    // const weekDates = getDatesBySetting(date, dateSetting);
    setDates(
      weekDates.map((weekDate) => ({
        date: weekDate,
        dateAsNumber: dateToNumber(weekDate),
      }))
    );
  }, [date, dateSetting]);

  return dates;
}

function getDatesBySetting(inputDate, setting) {
  let date = new Date(inputDate);
  let resultDates = [];

  switch (setting) {
    case "day":
      resultDates.push(new Date(date));
      break;
    case "3d":
      date.setDate(date.getDate() - 1); // Move to the previous day
      for (let i = 0; i < 3; i++) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1); // Move to the next day
      }
      break;
    case "week":
      let day = date.getUTCDay();
      let difference = day === 0 ? -6 : 1 - day;
      date.setUTCDate(date.getUTCDate() + difference); // Adjust to Monday
      for (let i = 0; i < 7; i++) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      break;
    case "month":
      let month = date.getMonth();
      date.setDate(1);
      while (date.getMonth() === month) {
        resultDates.push(new Date(date));
        date.setDate(date.getDate() + 1);
      }
      break;
    default:
      throw new Error("Invalid setting provided.");
  }

  return resultDates;
}
