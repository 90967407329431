const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export function dateToNumber(date) {
  const yyyy = date.getFullYear();
  const mm = String(date.getMonth() + 1).padStart(2, "0"); // January is 0, so we add 1
  const dd = String(date.getDate()).padStart(2, "0");

  return Number(`${yyyy}${mm}${dd}`);
}

export function isToday(date) {
  const today = new Date();

  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
}

export function dayOfWeek(date) {
  return weekdays[date.getDay()];
}

export function isSameDate(date1, date2) {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
}

export function normalizeDate(date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  // newDate.setTime(newDate.getTime() - newDate.getTimezoneOffset() * 60 * 1000);
  // return newDate;
}

export function toLocalISOString(date) {
  const pad = (number) => (number < 10 ? `0${number}` : number);

  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
    date.getSeconds()
  )}.${(date.getMilliseconds() / 1000).toFixed(3).slice(2, 5)}`;
}

export function isDateThisWeek(date) {
  const now = normalizeDate(new Date());
  const startOfWeek = new Date(now);
  startOfWeek.setDate(now.getDate() - now.getDay()); // Sunday as start of the week

  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  return date >= startOfWeek && date <= endOfWeek;
}
