import { dateToNumber } from "../../Features/Shared/Utils/Functions/dateToNumber";
import axiosInstance from "../../httpInterceptor";
const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export async function getBlocks(startDate, endDate) {
  return await axiosInstance.post("/blocks/dates", { startDate, endDate });
}

export async function addBlock(block) {
  return await axiosInstance.post("/blocks", {
    ...block,
  });
}

export async function resizeBlockEndpoint(block) {
  return await axiosInstance.patch("/blocks/resize/" + block._id, {
    start: block.start,
    end: block.end,
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}

export async function moveBlockEndpoint(block, oldDate) {
  return await axiosInstance.patch("/blocks/move/" + block._id, {
    start: block.start,
    end: block.end,
    date: block.date,
    isRecurrance: block.isRecurrance ?? false,
    recurranceId: block.recurranceId,
    oldDate,
  });
}
export async function deleteBlock(block) {
  return await axiosInstance.post("/blocks/delete/" + block._id, {
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}

export async function patchColor(block, color) {
  return await axiosInstance.patch("/blocks/" + block._id + "/color", {
    color,
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}

export async function patchTitle(block, title) {
  return axiosInstance.patch("/blocks/" + block._id + "/title", {
    title,
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}

export async function patchActivity(block, activityId) {
  return axiosInstance.patch("/blocks/" + block._id + "/activity", {
    activityId,
    date: block.date,
    isRecurrance: block.isRecurrance,
    recurranceId: block.recurranceId,
  });
}
