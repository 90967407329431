import { useEffect, useState } from "react";
import { useCalendarWidthContext } from "../../../Shared/Contexts/CalendarWidthContextProvider";

export function useColumnWidth() {
  const { calendarWidth } = useCalendarWidthContext();
  const [columnWidth, setColumnWidth] = useState(null);
  const [columnWidthInitialized, setColumnWidthInitialized] = useState(null);

  const getColumnCount = (width) => {
    if (width < 500) return 1;
    if (width < 1000) return 3;
    return 7;
  };

  useEffect(() => {
    if (calendarWidth === 0 || calendarWidth == null) {
      return;
    }

    const translatedWidth = calendarWidth - 38; // time width
    const columnCount = getColumnCount(translatedWidth);
    setColumnWidth((translatedWidth - (columnCount - 1) * 8) / columnCount);
    setColumnWidthInitialized(true);
  }, [calendarWidth]);

  return { columnWidth, columnWidthInitialized };
}
