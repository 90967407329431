import { dayOfWeek, isToday } from "../../Shared/Utils/Functions/dateToNumber";
import { getGridTemplate } from "../Utils/functions/getGridTemplate";
import targetIconGreen from "../../../Assets/Icons/target-green.svg";
import targetIconRed from "../../../Assets/Icons/target-red.svg";
import { useTargets } from "../../Targets/Utils/CustomHooks/useTargets";
import { useWeeklyTargets } from "../../Targets/Utils/CustomHooks/useWeeklyTargets";

export function ColumnDates({ dates, targetClicked }) {
  return (
    <div
      className="gap-2 ml-[46px] bg-transparent"
      style={{
        display: "grid",
        gridTemplateColumns: getGridTemplate(dates.length),
      }}
    >
      {dates.map((date) => (
        <DateComp
          key={date.dateAsNumber}
          date={date}
          targetClicked={targetClicked}
        />
      ))}
    </div>
  );
}

function DateComp({ date, targetClicked }) {
  const { areAchieved } = useTargets(date.date);
  const { weeklyTargetsAchieved } = useWeeklyTargets();
  return (
    <div className="w-full h-20 relative" key={date.dateAsNumber}>
      <div
        className={[
          isToday(date.date) ? "" : "opacity-40",
          "w-min mx-auto text-5xl text-black dark:text-white",
        ].join(" ")}
      >
        {date.date.getDate()}
      </div>
      <div
        className={[
          date.isToday ? "" : "opacity-40",
          "w-min mx-auto text-black dark:text-white",
        ].join(" ")}
      >
        {dayOfWeek(date.date)}
      </div>
      <button
        className="absolute right-0 bottom-0  cursor-pointer"
        onClick={() => targetClicked(date.date)}
      >
        {areAchieved ? (
          <img className="w-6" src={targetIconGreen} alt="" />
        ) : (
          <img className="w-6" src={targetIconRed} alt="" />
        )}
      </button>
    </div>
  );
}
