import { useEffect, useState } from "react";
import { useBlocksContext } from "../../../Shared/Contexts/BlocksContextProvider";
import { useActivityContext } from "../../../Shared/Contexts/ActivitiesContextProvider";
import { normalizeDate } from "../../../Shared/Utils/Functions/dateToNumber";

export function useTargets(date) {
  const { blocks } = useBlocksContext();
  const { activities } = useActivityContext();

  const [targets, setTargets] = useState([]);
  const [areAchieved, setAreAchieved] = useState([]);

  useEffect(() => {
    if (date == null) return;
    const normalizedDate = normalizeDate(date);
    const relativeBlocks = blocks.filter(
      (b) =>
        normalizeDate(new Date(b.date)).getTime() === normalizedDate.getTime()
    );

    const relativeTargets = getTargets(activities, normalizedDate).filter((t) =>
      filterTargetRange(t, date)
    );

    calculateTargetTime(relativeTargets, relativeBlocks, activities);

    setTargets(relativeTargets);

    setAreAchieved(
      relativeTargets.every((t) => {
        return (
          (t.type === "time" && t.totalTime >= t.time) ||
          (t.type === "block" && t.totalBlocks >= t.blocks)
        );
      })
    );
  }, [date, blocks, activities]);

  return { targets, areAchieved };
}

function filterTargetRange(target, date) {
  const dayOfWeek = date
    .toLocaleString("en-us", { weekday: "short" })
    .toLowerCase(); // 'mon', 'tue', etc.
  const isWeekday = date.getDay() > 0 && date.getDay() < 6; // Mon-Fri (1-5)

  if (target.range === "daily") {
    return true;
  }

  if (target.range === "custom" && target.customRange[dayOfWeek]) {
    return true;
  }

  if (target.range === "weekDays" && isWeekday) {
    return true;
  }

  return false;
}

function getTargets(activities, date) {
  // Convert the input date to a comparable date format
  const inputDate = normalizeDate(new Date(date));

  // Iterate over activities and collect matching targets
  const matchingTargets = activities.flatMap((activity) =>
    activity.targets
      .filter((target) => {
        const startDate = normalizeDate(new Date(target.startDate));
        const endDate = target.endDate
          ? normalizeDate(new Date(target.endDate))
          : null;

        // Check if the date falls between startDate and endDate (inclusive)
        return (
          startDate <= inputDate && (endDate === null || inputDate <= endDate)
        );
      })
      .map((target) => ({
        ...target,
        activityIsArchived: activity.isDone,
        activityId: activity._id, // Add the activityId to the target
      }))
  );

  return matchingTargets;
}

function calculateTargetTime(targets, blocks, activities) {
  targets.forEach((target) => {
    let totalTime = 0;

    // Find all activityIds that could match this target, including linked activities
    const relatedActivityIds = activities
      .filter((activity) => activity.categoryId === target.activityId)
      .map((activity) => activity._id);

    // Include the target's own activityId
    relatedActivityIds.push(target.activityId);

    const matchingBlocks = blocks.filter((block) =>
      relatedActivityIds.includes(block.activityId)
    );

    // Calculate the total time in minutes for these matching blocks
    matchingBlocks.forEach((block) => {
      // Calculate the number of 15-minute intervals and convert it to minutes
      const blockDurationMinutes = (block.end - block.start + 1) * 15;
      totalTime += blockDurationMinutes;
    });
    target.totalTime = totalTime;
    target.totalBlocks = matchingBlocks.length;

    // Check the condition based on minMax property
    if (target.minMax === "min") {
      // Same logic as before for 'min'
      if (target.type === "time" && target.totalTime >= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks >= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    } else if (target.minMax === "max") {
      // New logic for 'max'
      if (target.type === "time" && target.totalTime <= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks <= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    } else {
      // Default to 'min' behavior if minMax is not specified
      if (target.type === "time" && target.totalTime >= target.time)
        target.isDone = true;
      else if (target.type === "block" && target.totalBlocks >= target.blocks)
        target.isDone = true;
      else target.isDone = false;
    }
  });
}
