import arrowDown from "../../../Assets/Icons/arrow-down.svg";
import arrowRight from "../../../Assets/Icons/arrow-right.svg";
import { useState } from "react";

function ActivitySection({ children, title }) {
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen((isOpen) => !isOpen);
  };

  return (
    <div
      role="button"
      className="cursor-pointer select-none"
      onClick={handleMenuClick}
    >
      <div className="p-3">
        <div className="pb-1 flex items-center gap-2">
          <span>
            {open ? (
              <img className="h-1" src={arrowDown} alt="" />
            ) : (
              <img className="w-1" src={arrowRight} alt="" />
            )}
          </span>

          <span className="text-sm">{title}</span>
        </div>
        {open ? <div>{children}</div> : ""}
      </div>
    </div>
  );
}

export default ActivitySection;
