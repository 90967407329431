import axiosInstance from "../../httpInterceptor";

export async function postRecurrance(blockId, pattern, startDate) {
  return await axiosInstance.post("/recurrances/" + blockId, {
    pattern,
    startDate,
  });
}

export async function moveRecurrance(block) {
  return await axiosInstance.patch("/recurrances/move/" + block.recurranceId, {
    start: block.start,
    end: block.end,
    option: block.selectedRadioOption,
    fromDate: block.date,
  });
}

export async function resizeRecurrance(block) {
  return await axiosInstance.patch(
    "/recurrances/resize/" + block.recurranceId,
    {
      start: block.start,
      end: block.end,
      option: block.selectedRadioOption,
      fromDate: block.date,
    }
  );
}

export async function patchRecurranceColor(block, color) {
  return await axiosInstance.patch(
    "/recurrances/" + block.recurranceId + "/color",
    {
      color,
      option: block.selectedRadioOption,
      fromDate: block.date,
    }
  );
}

export async function patchRecurranceTitle(block, title) {
  return await axiosInstance.patch(
    "/recurrances/" + block.recurranceId + "/title",
    {
      title,
      option: block.selectedRadioOption,
      fromDate: block.date,
    }
  );
}

export async function patchRecurrancePattern(block, pattern) {
  return await axiosInstance.patch(
    "/recurrances/pattern/" + block.recurranceId,
    {
      pattern,
    }
  );
}
export async function patchRecurranceActivity(block, activity) {
  return await axiosInstance.patch(
    "/recurrances/" + block.recurranceId + "/activity",
    {
      activityId: activity?.id ?? null,
      option: block.selectedRadioOption,
      fromDate: block.date,
    }
  );
}

export async function patchRecurranceActionSuccess(
  endpoint,
  callback,
  data,
  option
) {
  return await axiosInstance.patch(endpoint, { ...data, option });
}
