import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useBlocksContext } from "./BlocksContextProvider";
import { useSelectedDates } from "../../TimeBlocker/Utils/CustomHooks/useSelectedDates";
import { useActivityContext } from "./ActivitiesContextProvider";
import { getBlocks } from "../../../Endpoints/Blocks/BlocksEndpoints";
import { defaultBlockColor } from "../../../App";
import { getUpdatedBlockPositions } from "../../TimeBlocker/Utils/functions/BlockPosition";
import { useColumnWidth } from "../../TimeBlocker/Utils/CustomHooks/useColumnWidth";
import { isSameDate } from "../Utils/Functions/dateToNumber";

const ColumnStateContext = createContext(null);

export const ColumnStateProvider = ({ children }) => {
  const { setBlocks, setLoadingBlocks } = useBlocksContext();
  const selectedDates = useSelectedDates();
  const { columnWidth, columnWidthInitialized } = useColumnWidth();
  const { activities, activitiesLoaded } = useActivityContext();
  const activitiesRef = useRef(activities);
  useEffect(() => {
    activitiesRef.current = activities;
  }, [activities]);

  const activitiesLoadedRef = useRef(activitiesLoaded);
  useEffect(() => {
    activitiesLoadedRef.current = activitiesLoaded;
  }, [activitiesLoaded]);

  const [blocksPreActivitiesLoaded, setBlocksPreActivitiesLoaded] =
    useState(null);

  useEffect(() => {
    if (blocksPreActivitiesLoaded === null || activitiesLoaded === false)
      return;

    const blocks = blocksPreActivitiesLoaded?.map((b) => {
      return { ...insertActivity(b, b.activityId) };
    });

    let indentedBlocks = [];
    for (const date of selectedDates) {
      indentedBlocks = [
        ...indentedBlocks,
        ...getUpdatedBlockPositions(
          blocks.filter((b) => isSameDate(b.date, date.date)),
          columnWidth - 10
        ),
      ];
    }

    setBlocks((current) => indentedBlocks);
    setLoadingBlocks(false);
  }, [blocksPreActivitiesLoaded, activitiesLoaded]);

  const loadColumns = () => {
    if (selectedDates === null || selectedDates.length === 0) return;

    setLoadingBlocks(true);
    getBlocks(selectedDates[0].date, selectedDates.slice(-1)[0].date).then(
      (response) => {
        let responseBlocks = [
          ...response.data.map((b) => ({ ...b, date: new Date(b.date) })),
        ];

        if (activitiesLoadedRef.current !== true) {
          setBlocksPreActivitiesLoaded(responseBlocks);
          return;
        }

        responseBlocks = responseBlocks?.map((b) => {
          return { ...insertActivity(b, b.activityId) };
        });

        let blocks = [];
        for (const date of selectedDates) {
          blocks = [
            ...blocks,
            ...getUpdatedBlockPositions(
              responseBlocks.filter((b) => isSameDate(b.date, date.date)),
              columnWidth - 10
            ),
          ];
        }

        setBlocks((current) => blocks);
        setLoadingBlocks(false);
      }
    );
  };

  const insertActivity = (block, activityId) => {
    const activity = activitiesRef.current.find((a) => a.id === activityId);

    if (activity === null || activity === undefined || activityId == null) {
      return {
        ...block,
        color: block.hasCustomColor ? block.color : defaultBlockColor,
      };
    }

    const updatedBlock = { ...block };

    updatedBlock.color = updatedBlock.hasCustomColor
      ? updatedBlock.color
      : activity.color;
    if (!block.hasCustomTitle) updatedBlock.title = activity.title;

    return updatedBlock;
  };

  useEffect(() => {
    if (columnWidthInitialized === false) return;
    loadColumns();
  }, [selectedDates, columnWidthInitialized]);

  return (
    <ColumnStateContext.Provider value={{ loadColumns }}>
      {children}
    </ColumnStateContext.Provider>
  );
};

export const useColumnStateUpdater = () => {
  const context = useContext(ColumnStateContext);
  if (!context) {
    throw new Error(
      "useColumnStateUpdater must be used within a ColumnStateProvider"
    );
  }
  return context;
};
