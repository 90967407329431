import { createContext, useContext, useEffect, useState } from "react";
import { useActiveContextMenuContext } from "./ActiveContextMenuProvider";
import { useBlocksContext } from "./BlocksContextProvider";
import { useColumnWidth } from "../../TimeBlocker/Utils/CustomHooks/useColumnWidth";
import { useRecurringContext } from "./RecurringContextProvider";
import { useActivityContext } from "./ActivitiesContextProvider";
import { GetUpdatedIndentions } from "../Utils/Functions/GetUpdatedIndentions";
import {
  deleteBlock,
  patchActivity,
  patchColor,
  patchTitle,
} from "../../../Endpoints/Blocks/BlocksEndpoints";
import { useDebounce } from "use-debounce";
import {
  patchRecurranceActivity,
  patchRecurranceColor,
  patchRecurrancePattern,
  patchRecurranceTitle,
} from "../../../Endpoints/Recurrance/RecurranceEndpoints";
import { useColumnStateUpdater } from "./ColumnStateContextProvider";
import { defaultBlockColor } from "../../../App";

const EditBlockContext = createContext(null);

export const EditBlockProvider = ({ children }) => {
  const { activeContextBlock, setActiveContextBlock } =
    useActiveContextMenuContext();
  const { blocks, setBlocks } = useBlocksContext();
  const { columnWidth } = useColumnWidth();
  const { setEndpoint, setCallback, handleRecurringChange } =
    useRecurringContext();
  const [recurringPatternModalOpen, setRecurringPatternModalOpen] =
    useState(false);
  const [color, setColor] = useState(activeContextBlock?.color);
  const [colorUpdated, setColorUpdated] = useState(false);
  const { activities } = useActivityContext();
  const { loadColumns } = useColumnStateUpdater();

  const [colorChangeValues, setColorChangeValues] = useState(null);
  const [debouncedColorChangeValues] = useDebounce(colorChangeValues, 200);

  const [titleChange, setTitleChange] = useState(null);
  const [debouncedTitleChange] = useDebounce(titleChange, 500);

  useEffect(() => {
    if (activeContextBlock == null) return;
    const contextBlock = blocks.find((b) => b._id === activeContextBlock._id);
    setActiveContextBlock(contextBlock);
  }, [blocks]);

  useEffect(() => {
    if (activeContextBlock == null) return;
    setColor(activeContextBlock.color);
    setColorUpdated(false);
  }, [activeContextBlock]);

  const blockActivity = activities.find(
    (activity) => activity.id === activeContextBlock?.activityId
  );

  const handleColorChange = (value) => {
    setColor(value);
    setColorUpdated(true);
  };

  const handleColorBlur = () => {
    if (colorUpdated) setColorChangeValues(color);
  };

  useEffect(() => {
    if (debouncedColorChangeValues === null) return;
    console.log(activeContextBlock);

    if (
      activeContextBlock.selectedRadioOption === "future" ||
      activeContextBlock.selectedRadioOption === "all"
    ) {
      patchRecurranceColor(activeContextBlock, debouncedColorChangeValues).then(
        (response) => {
          setBlocks((current) => {
            return current.map((b) => {
              return {
                ...b,
                ...((activeContextBlock.selectedRadioOption === "all" ||
                  b.date - activeContextBlock.date >= 0) &&
                  b.recurranceId === activeContextBlock.recurranceId && {
                    color: debouncedColorChangeValues,
                    hasCustomColor: response.data.hasCustomColor,
                    recurranceId: response.data._id,
                  }),
              };
            });
          });
        }
      );
      return;
    }
    patchColor(activeContextBlock, debouncedColorChangeValues).then(
      (response) => {
        setBlocks((current) =>
          current.map((b) =>
            b._id === activeContextBlock._id
              ? {
                  ...b,
                  color: response.data.color,
                  hasCustomColor: response.data.hasCustomColor,
                }
              : b
          )
        );
      }
    );
  }, [debouncedColorChangeValues]);

  const onRemove = () => {
    setBlocks((currentBlocks) =>
      currentBlocks.filter((b) => b._id !== activeContextBlock._id)
    );
    const date = activeContextBlock.date;

    setBlocks((currentBlocks) =>
      GetUpdatedIndentions(currentBlocks, date, columnWidth)
    );
    deleteBlock(activeContextBlock, activeContextBlock.date);
    setActiveContextBlock(null);
  };

  const handleTitleChange = (title) => {
    setTitleChange({
      title,
      blockId: activeContextBlock._id,
      block: activeContextBlock,
    });
    setBlocks((current) =>
      current.map((b) =>
        b._id === activeContextBlock._id ? { ...b, title } : b
      )
    );
  };

  useEffect(() => {
    if (debouncedTitleChange === null) return;

    if (
      debouncedTitleChange.block.selectedRadioOption === "future" ||
      debouncedTitleChange.block.selectedRadioOption === "all"
    ) {
      patchRecurranceTitle(
        debouncedTitleChange.block,
        debouncedTitleChange.title
      ).then((response) => {
        setBlocks((current) => {
          return current.map((b) => {
            return {
              ...b,
              ...((debouncedTitleChange.block.selectedRadioOption === "all" ||
                b.date - debouncedTitleChange.block.date >= 0) &&
                b.recurranceId === debouncedTitleChange.block.recurranceId && {
                  title: debouncedTitleChange.title,
                  recurranceId: response.data._id,
                }),
            };
          });
        });
      });
      return;
    }

    patchTitle(debouncedTitleChange.block, debouncedTitleChange.title).then(
      (response) => {
        setBlocks((current) =>
          current.map((b) =>
            b._id === debouncedTitleChange.block._id
              ? {
                  ...b,
                  title: response.data.title,
                  hasCustomTitle: response.data.hasCustomTitle,
                }
              : b
          )
        );
      }
    );
  }, [debouncedTitleChange]);

  const handleRemoveActivity = () => {
    const blockToUpdate = { ...activeContextBlock };
    if (
      blockToUpdate.selectedRadioOption === "future" ||
      blockToUpdate.selectedRadioOption === "all"
    ) {
      patchRecurranceActivity(blockToUpdate, null).then((response, option) => {
        setBlocks((current) => {
          return current.map((b) => {
            return {
              ...b,
              ...((blockToUpdate.selectedRadioOption === "all" ||
                b.date - blockToUpdate.date >= 0) &&
                b.recurranceId === blockToUpdate.recurranceId && {
                  activityId: null,
                  color: blockToUpdate.hasCustomColor
                    ? blockToUpdate.color
                    : defaultBlockColor,
                  title: blockToUpdate.hasCustomTitle
                    ? blockToUpdate.title
                    : "",
                  recurranceId: response.data._id,
                }),
            };
          });
        });
      });
      return;
    }

    patchActivity(blockToUpdate, null).then((response) => {
      setBlocks((current) =>
        current.map((b) =>
          b._id === blockToUpdate._id
            ? {
                ...b,
                color: b.hasCustomColor ? b.color : defaultBlockColor,
                title: b.hasCustomTitle ? b.title : "",
                activityId: null,
              }
            : b
        )
      );
    });
  };

  const onRecurringChange = (recurring) => {
    if (recurring === false) {
      handleRecurringChange(activeContextBlock, false);
    } else {
      setRecurringPatternModalOpen(true);
    }
  };

  const onRecurringPatternSuccess = (pattern) => {
    handleRecurringChange(activeContextBlock, true, pattern);
  };

  const handleRadioChange = (value) => {
    setBlocks((current) =>
      current.map((b) =>
        b._id === activeContextBlock._id
          ? { ...b, selectedRadioOption: value }
          : b
      )
    );
  };

  const handlePatternChange = (value) => {
    patchRecurrancePattern(activeContextBlock, value).then((response) => {
      loadColumns();
    });
  };

  return (
    <EditBlockContext.Provider
      value={{
        color,
        handleColorChange,
        handleColorBlur,
        onRemove,
        handleTitleChange,
        blockActivity,
        handleRemoveActivity,
        onRecurringChange,
        handleRadioChange,
        handlePatternChange,
        recurringPatternModalOpen,
        setRecurringPatternModalOpen,
        onRecurringPatternSuccess,
      }}
    >
      {children}
    </EditBlockContext.Provider>
  );
};

export const useEditBlockContext = () => {
  const context = useContext(EditBlockContext);
  if (!context) {
    throw new Error("useEditBlock must be used within an EditBlockProvider");
  }
  return context;
};
