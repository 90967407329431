import React, { useState } from "react";
import ellipsis from "../../../Assets/Icons/ellipsis.svg";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";

export function ActivityItem({
  id,
  properties,
  onDelete,
  onEdit,
  onMarkAsDone,
  onTargetsClicked,
  canMarkAsDone,
  showOptions = true,
}) {
  const handleDragStart = (event) => {
    event.dataTransfer.setData("activityId", id);
  };
  const handleDelete = () => {
    onDelete(id);
  };
  const handleEdit = () => {
    onEdit(id);
  };

  const handleMarkAsDone = () => {
    onMarkAsDone(id);
  };

  const handleTargetsClicked = () => {
    onTargetsClicked(id);
  };
  return (
    <div
      className="p-2 flex items-center gap-2 rounded relative"
      draggable={true}
      onDragStart={handleDragStart}
    >
      <div
        className="w-2 h-2"
        style={{ backgroundColor: properties.color }}
      ></div>
      <h4 className="text-sm">{properties.title}</h4>

      {showOptions ? (
        <Options
          onDelete={handleDelete}
          onEdit={handleEdit}
          onMarkAsDone={handleMarkAsDone}
          isDone={properties.isDone}
          canMarkAsDone={canMarkAsDone}
          onTargetsClicked={handleTargetsClicked}
        />
      ) : (
        ""
      )}
    </div>
  );
}

function Options({
  onDelete,
  onEdit,
  onMarkAsDone,
  isDone,
  canMarkAsDone,
  onTargetsClicked,
}) {
  return (
    <Menu as="div" className="relative text-left ml-auto flex align-middle">
      <Menu.Button className="w-full justify-center gap-x-1.5 px-3 py-2 text-sm font-semibold text-white dark:text-gray-900 ring-secondaryLight dark:ring-lightDark">
        <img className="w-3" src={ellipsis} alt="menu icon" />
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-[#515151] text-black dark:text-white shadow-lg ring-1 ring-white dark:ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={onDelete}
                  className={[
                    active
                      ? "bg-gray-100 text-gray-900"
                      : "text-black dark:text-white",
                    "block px-4 py-2 text-sm",
                  ].join(" ")}
                >
                  Delete
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={onEdit}
                  className={[
                    active
                      ? "bg-gray-100 text-gray-900"
                      : "text-black dark:text-white",
                    "block px-4 py-2 text-sm",
                  ].join(" ")}
                >
                  Edit
                </div>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <div
                  onClick={onTargetsClicked}
                  className={[
                    active
                      ? "bg-gray-100 text-gray-900"
                      : "text-black dark:text-white",
                    "block px-4 py-2 text-sm",
                  ].join(" ")}
                >
                  Targets & Deadlines
                </div>
              )}
            </Menu.Item>
            {canMarkAsDone && (
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={onMarkAsDone}
                    className={[
                      active
                        ? "bg-gray-100 text-gray-900"
                        : "text-black dark:text-white",
                      "block px-4 py-2 text-sm",
                    ].join(" ")}
                  >
                    {isDone ? "Mark As Not Done" : "Mark As Done"}
                  </div>
                )}
              </Menu.Item>
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
