import { Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import LandingPage from "./Features/LandingPage/Components/LandingPage";
import {
  UserContextProvider,
  useUserContext,
} from "./Features/Shared/Contexts/UserContextProvider";
import TimeBlockerPage from "./Features/TimeBlocker/Components/TimeBlockerPage";
import { ActivityContextProvider } from "./Features/Shared/Contexts/ActivitiesContextProvider";
import TimeBlocker from "./Features/TimeBlocker/Components/TimeBlocker";
import { DateCountContextProvider } from "./Features/Shared/Contexts/DateCountContextProvider";
import { DateContextProvider } from "./Features/Shared/Contexts/DateContextProvider";
import { BlocksContextProvider } from "./Features/Shared/Contexts/BlocksContextProvider";
import { BlockAdjustmentsContextProvider } from "./Features/Shared/Contexts/BlockAdjustmentsProvider";
import { CalendarWidthContextProvider } from "./Features/Shared/Contexts/CalendarWidthContextProvider";
import { ActiveContextMenuProvider } from "./Features/Shared/Contexts/ActiveContextMenuProvider";
import { RecurringContextProvider } from "./Features/Shared/Contexts/RecurringContextProvider";
import { EditBlockProvider } from "./Features/Shared/Contexts/EditBlockContextProvider";
import { ColumnStateProvider } from "./Features/Shared/Contexts/ColumnStateContextProvider";
import { ActivitiesPage } from "./Features/Activities/Components/ActivitiesPage";
import { Login } from "./Features/Login/Components/Login";
import { signInAnonymous } from "./Features/Shared/Utils/Auth";
import { Statistics } from "./Features/Statistics/Components/Statistics";
import { StatisticsContextProvider } from "./Features/Shared/Contexts/StatisticsContextProvider";
import { TimeSpanContextProvider } from "./Features/Shared/Contexts/TimeSpanContextProvider";
import { LandingPage2 } from "./Features/LandingPage/Components/LandingPage2";

export const defaultBlockColor = "#4A88A4"; //blue

function App() {
  return (
    <div>
      <Providers>
        <Routes>
          <Route path="/" element={<DefaultRouter />} />
          <Route path="/home" element={<LandingPage2 />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/timeBlocker"
            element={
              <ProtectedRoute>
                <TimeBlockerProviders>
                  <TimeBlockerPage />
                </TimeBlockerProviders>
              </ProtectedRoute>
            }
          >
            <Route path="blocks" element={<TimeBlocker />} />
            <Route path="activities" element={<ActivitiesPage />} />
            <Route
              path="statistics"
              element={
                <StatisticsProviders>
                  <Statistics />
                </StatisticsProviders>
              }
            />
          </Route>
        </Routes>
      </Providers>
    </div>
  );
}

function ProtectedRoute({ children }) {
  const { user } = useUserContext();
  if (user === undefined) {
    return null;
  } else if (user === null) {
    signInAnonymous();
    return null;
    // return <Navigate to="/login" replace />;
  }

  return children;
}

function DefaultRouter() {
  const { user } = useUserContext();

  if (user === undefined) {
    return null;
  } else if (user === null) {
    return <Navigate to="/home" replace />;
  }
  return <Navigate to="/timeBlocker/blocks" replace />;
}

function Providers({ children }) {
  return (
    <>
      <UserContextProvider>
        <>{children}</>
      </UserContextProvider>
    </>
  );
}

function StatisticsProviders({ children }) {
  return (
    <>
      <StatisticsContextProvider>
        <DateContextProvider>
          <TimeSpanContextProvider>
            <>{children}</>
          </TimeSpanContextProvider>
        </DateContextProvider>
      </StatisticsContextProvider>
    </>
  );
}

function TimeBlockerProviders({ children }) {
  return (
    <>
      <CalendarWidthContextProvider>
        <DateContextProvider>
          <DateCountContextProvider>
            <ActivityContextProvider>
              <BlocksContextProvider>
                <BlockAdjustmentsContextProvider>
                  <ColumnStateProvider>
                    <RecurringContextProvider>
                      <ActiveContextMenuProvider>
                        <EditBlockProvider>
                          <>{children}</>
                        </EditBlockProvider>
                      </ActiveContextMenuProvider>
                    </RecurringContextProvider>
                  </ColumnStateProvider>
                </BlockAdjustmentsContextProvider>
              </BlocksContextProvider>
            </ActivityContextProvider>
          </DateCountContextProvider>
        </DateContextProvider>
      </CalendarWidthContextProvider>
    </>
  );
}

export default App;
