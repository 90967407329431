import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useStatisticsContext } from "../../Shared/Contexts/StatisticsContextProvider";

ChartJS.register(ArcElement, Tooltip, Legend);

export function CategoriesStats({ onSelectCategory }) {
  const { stats } = useStatisticsContext();
  if (stats == null) return <></>;

  // Prepare data for the chart
  const data = {
    labels: stats.categories.map((category) => category.title),
    datasets: [
      {
        data: stats.categories.map((category) => category.totalTime),
        backgroundColor: stats.categories.map((category) => category.color),
        hoverOffset: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    onClick: (event, elements) => {
      if (elements[0] == null) return;
      const index = elements[0].index;
      const category = stats.categories[index];
      onSelectCategory(category);
    },
    plugins: {
      legend: {
        position: "bottom",
      },
    },
  };

  return <Doughnut data={data} options={options} />;
}
