import { useEffect, useState } from "react";
import { useBlocksContext } from "../../Shared/Contexts/BlocksContextProvider";
import { Block } from "./Block";
import { isSameDate } from "../../Shared/Utils/Functions/dateToNumber";
import { useBlockAdjustmentsContext } from "../../Shared/Contexts/BlockAdjustmentsProvider";

export function Column({ date }) {
  const { blocks } = useBlocksContext();
  const [columnBlocks, setColumnBlocks] = useState([]);
  useEffect(() => {
    setColumnBlocks(blocks.filter((b) => isSameDate(b.date, date)));
  }, [blocks, date]);

  const isUpdating = false;

  const { setEnteredSegment, onSegmentPressed } = useBlockAdjustmentsContext();

  const onEnter = (segmentIndex) => {
    setEnteredSegment((current) => ({
      segment: segmentIndex,
      date,
    }));
  };

  const onMouseDown = (segmentIndex) => {
    onSegmentPressed(segmentIndex, date);
  };

  return (
    <div>
      <div className="flex flex-col w-full relative" draggable="false">
        {renderSegments(onMouseDown, onEnter)}
        {columnBlocks.map((block) => {
          // const isUpdating = block.isRecurrance
          // ? updatingRecurranceIds[block.recurranceId] ?? false
          // : updatingBlockIds[block._id] ?? false;
          return (
            <Block key={block._id} block={block} isUpdating={isUpdating} />
          );
        })}
      </div>
    </div>
  );
}

function renderSegments(onMouseDown, onEnter) {
  const segments = [];
  for (let i = 0; i < 24 * 4; i++) {
    segments.push(
      <div
        onMouseDown={(e) => {
          if (e.button === 0) onMouseDown(i);
        }}
        onMouseEnter={() => onEnter(i)}
        draggable="false"
        key={i}
        className={[
          "w-full h-3 cursor-pointer select-none ",
          i % 4 === 0 ? "border-t border-light" : "",
        ].join(" ")}
      >
        &nbsp;
      </div>
    );
  }
  return segments;
}
