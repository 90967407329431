import { createContext, useEffect, useState } from "react";
import { useActivityContext } from "../../Shared/Contexts/ActivitiesContextProvider";
import { ActivitiesHeader } from "./ActivitiesHeader";
import { ActivityItem } from "./ActivityItem";
import addRound from "../../../Assets/Icons/add-round.svg";
import addRoundBlack from "../../../Assets/Icons/add-round-black.svg";
import { useActivityOptions } from "../CustomHooks/UseActivityOptions";
import AddActivityModal from "./AddActivityModal";
import AddCategoryModal from "./AddCategoryModal";
import EditActivityModal from "./EditActivityModal";
import EditCategoryModal from "./EditCategoryModal";
import { ModalContext } from "../../Shared/Contexts/ModalContextProvider";
import { AddTargetModal } from "../../Targets/Components/AddTargetModal";
import {
  DeleteTarget,
  EditTarget,
  PostTarget,
} from "../../../Endpoints/Activities/ActivitiesEndpoints";

export function ActivitiesPage() {
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);
  const [editActivityModalOpen, setEditActivityModalOpen] = useState(false);
  const [editCategoryModalOpen, setEditCategoryModalOpen] = useState(false);
  const [targetsModalOpen, setTargetsModalOpen] = useState(false);
  const [type, setType] = useState(null);
  const [editActivity, setEditActivity] = useState(null);
  const { activities, setActivities } = useActivityContext();
  const [filter, setFilter] = useState({ show: "active" });

  const {
    handleNewActivity,
    handleActivityEdited,
    handleCategoryEdited,
    handleNewCategory,
    handleDeleteActivity,
    handleDeleteCategory,
    handleActivityMarkedAsDone,
  } = useActivityOptions();

  useEffect(() => {
    if (editActivity == null) return;
    setEditActivity((current) => activities.find((a) => a._id === current._id));
  }, [activities]);

  const handleOpenModal = (type) => {
    setType(type);
    setAddActivityModalOpen(true);
  };

  const handleOpenCategoryModal = () => {
    setCategoryModalOpen(true);
  };
  const handleEditActivity = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditActivityModalOpen(true);
  };
  const handleEditCategory = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setEditCategoryModalOpen(true);
  };
  const handleTargetsClicked = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    setEditActivity(activity);
    setTargetsModalOpen(true);
  };
  const handleMarkAsDone = (activityId) => {
    const activity = activities.find((a) => a._id === activityId);
    handleActivityMarkedAsDone({ ...activity, isDone: !activity.isDone });
  };

  const handleAddTarget = (target) => {
    PostTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };
  const handleEditTarget = (target) => {
    EditTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };
  const handleDeleteTarget = (target) => {
    DeleteTarget(target, editActivity._id).then((response) => {
      const updatedActivity = response.data;

      setActivities((CurrentActivities) =>
        CurrentActivities.map((a) =>
          a._id === updatedActivity._id ? { ...updatedActivity } : a
        )
      );
    });
  };

  return (
    <div>
      <ModalContext.Provider
        value={[addActivityModalOpen, setAddActivityModalOpen]}
      >
        <AddActivityModal
          categories={activities.filter((a) => a.type === "Categories")}
          onCreateActivity={handleNewActivity}
          type={type}
          onTypeChange={setType}
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={[categoryModalOpen, setCategoryModalOpen]}>
        <AddCategoryModal onCreateCategory={handleNewActivity} />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editActivityModalOpen, setEditActivityModalOpen]}
      >
        <EditActivityModal
          onEditActivity={handleActivityEdited}
          activity={editActivity}
          categories={activities.filter((a) => a.type === "Categories")}
        />
      </ModalContext.Provider>
      <ModalContext.Provider
        value={[editCategoryModalOpen, setEditCategoryModalOpen]}
      >
        <EditCategoryModal
          onEditCategory={handleActivityEdited}
          category={editActivity}
        />
      </ModalContext.Provider>
      <ModalContext.Provider value={[targetsModalOpen, setTargetsModalOpen]}>
        <AddTargetModal
          activity={editActivity}
          onAddTarget={handleAddTarget}
          onEditTarget={handleEditTarget}
          onDeleteTarget={handleDeleteTarget}
        />
      </ModalContext.Provider>

      <ActivitiesHeader filter={filter} setFilter={setFilter} />
      <div className="px-12 w-min mx-auto">
        <div className="flex gap-3">
          <ActivityColumn
            title={"Critical & Immediate"}
            activities={activities.filter(
              (activity) => activity.type === "Critical & Immediate"
            )}
            onAdd={() => handleOpenModal("Critical & Immediate")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />

          <ActivityColumn
            title={"Personal Projects & Initiatives"}
            activities={activities.filter(
              (activity) => activity.type === "Personal Projects & Initiatives"
            )}
            onAdd={() => handleOpenModal("Personal Projects & Initiatives")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />
          <ActivityColumn
            title={"Routine Activites"}
            activities={activities.filter(
              (activity) => activity.type === "Routine Activites"
            )}
            onAdd={() => handleOpenModal("Routine Activites")}
            onDelete={handleDeleteActivity}
            onEdit={handleEditActivity}
            onMarkAsDone={handleMarkAsDone}
            filter={filter}
            canMarkAsDone={true}
            onTargetsClicked={handleTargetsClicked}
          />
          <div className="ml-5">
            <ActivityColumn
              title={"Categories"}
              activities={activities.filter(
                (activity) => activity.type === "Categories"
              )}
              onAdd={handleOpenCategoryModal}
              onDelete={handleDeleteActivity}
              onEdit={handleEditCategory}
              canMarkAsDone={false}
              onTargetsClicked={handleTargetsClicked}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function ActivityColumn({
  title,
  activities,
  onAdd,
  onDelete,
  onEdit,
  onMarkAsDone,
  filter,
  canMarkAsDone,
  onTargetsClicked,
}) {
  return (
    <div className="w-52 p-3 rounded-md min-h-[600px] bg-secondaryLight text-black dark:text-white dark:bg-dark flex flex-col gap-1">
      <div className="h-14 flex">
        <h3>{title}</h3>
        <button className="ml-auto h-min mt-1" onClick={() => onAdd()}>
          <img className="hidden dark:block" src={addRound} alt="plus icon" />
          <img className="dark:hidden" src={addRoundBlack} alt="plus icon" />
        </button>
      </div>
      {filterActivities(activities, filter).map((activity) => (
        <ActivityItem
          key={activity._id}
          id={activity._id}
          properties={{
            color: activity.color,
            title: activity.title,
            isDone: activity.isDone,
          }}
          onDelete={onDelete}
          onEdit={onEdit}
          onMarkAsDone={onMarkAsDone}
          canMarkAsDone={canMarkAsDone}
          onTargetsClicked={onTargetsClicked}
        />
      ))}
    </div>
  );
}

function filterActivities(activities, filter) {
  if (filter == null || filter.show == null) return activities;
  return activities.filter((activity) => {
    if (filter.show === "all") return true;
    else if (filter.show === "done") return activity.isDone === true;
    else if (filter.show === "active") return activity.isDone === false;
    return true;
  });
}
